@mixin respond-to-max($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@mixin respond-from-min($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@mixin height-to-max($media) {
  @media only screen and (max-height: $media) {
    @content;
  }
}

@mixin height-from-min($media) {
  @media only screen and (min-height: $media) {
    @content;
  }
}

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin h1 {
  font-size: 80px;
  @include respond-to-max($extraLarge) {
    font-size: calc(36px + (80 - 36) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin h2 {
  font-size: 48px;
  @include respond-to-max($extraLarge) {
    font-size: calc(24px + (48 - 24) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin h3 {
  font-size: 28px;
  line-height: 1.5;
  @include respond-to-max($extraLarge) {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin h4 {
  font-size: 16px;
  @include respond-to-max($extraLarge) {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin h5 {
  font-size: 20px;
  @include respond-to-max($extraLarge) {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-32 {
  font-size: 32px;
  @include respond-to-max($extraLarge) {
    font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-28 {
  font-size: 28px;
  line-height: 1.5;
  @include respond-to-max($extraLarge) {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-24 {
  font-size: 24px;
  @include respond-to-max($extraLarge) {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-20 {
  font-size: 20px;
  @include respond-to-max($extraLarge) {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-18 {
  font-size: 18px;
  line-height: 1.6;
  @include respond-to-max($extraLarge) {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-16 {
  font-size: 16px;
  line-height: 1.6;
  @include respond-to-max($extraLarge) {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-13 {
  font-size: 13px;
  @include respond-to-max($extraLarge) {
    font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-14 {
  font-size: 14px;
  @include respond-to-max($extraLarge) {
    font-size: calc(14px + (14 - 14) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin fs-small {
  font-size: 13px;
}

@mixin fs-lead {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 500;
  @include respond-to-max($extraLarge) {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1439 - 320)));
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }

  &[placeholder] {
    text-overflow: ellipsis;
  }
  &::-moz-placeholder {
    text-overflow: ellipsis;
  }
  &:-moz-placeholder {
    text-overflow: ellipsis;
  }
  &:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus::-moz-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  }
  &:focus:-ms-input-placeholder {
    color: transparent;
  }

  &::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

@mixin scroll-bar($width, $color-track) {
  &::-webkit-scrollbar {
    border-radius: $width / 2.5;
    width: $width;
  }
  &::-webkit-scrollbar-track {
    border-radius: $width / 2.5;
    background-color: $color-track;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $width / 2.5;
    background-color: darken($color-track, 30%);
  }
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin hoverDesktop() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hoverMobile() {
  @media (hover: none), (hover: hover) and (pointer: coarse) {
    @content;
  }
}
