/*
Use below example css properties for
the elements to start animation

animation-name: <NAME_OF_THE_KEYFRAME>;
animation-duration: 0.5s;

(or)

Shorthand property combines six of the animation
properties into a single property.

animation: <NAME_OF_THE_KEYFRAME> 0.5s;
*/

@for $i from 1 through 20 {
  .animation-delay-#{$i} {
    animation-fill-mode: both;
    animation-delay: $i * 0.25s;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100px, 0, 0);
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation: slideInRight 1.2s;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100px, 0, 0);
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation: slideInLeft 1.2s;
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation: slideInUp 1.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1.2s;
}

@keyframes scan {
  0%,
  100% {
    height: 250px;
  }
  100% {
    height: 5px;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.08, 1.08, 1.08);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}