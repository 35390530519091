@import "variables";
@import "mixins";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
}
html,
button,
input,
textarea,
a {
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

a {
  text-decoration: none !important;
  color: $text-black;
  &:hover {
    opacity: 0.8;
  }
}

strong {
  font-weight: 700 !important;
}

@include headings {
  font-weight: 700 !important;
}

// customize material typography
.mat-typography,
.mat-toolbar,
.mat-card .mat-card-subtitle,
.mat-card-content {
  color: $body-color;
  @include headings {
    color: $body-color;
    margin: inherit;
  }
  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }
  h3 {
    @include h3;
  }
  h4 {
    @include h4;
  }
  h5 {
    @include h5;
  }
  p {
    @include fs-16;
    color: $body-color;
  }
}

.almo-h1 {
  @include h1;
}
.almo-h2 {
  @include h2;
}
.almo-h3 {
  @include h3;
}
.almo-h4 {
  @include h4;
}
.almo-h5 {
  @include h5;
}
.almo-fs-32 {
  @include fs-32;
}
.almo-fs-28 {
  @include fs-28;
}
.almo-fs-24 {
  @include fs-24;
}
.almo-fs-20 {
  @include fs-20;
}
.almo-fs-18 {
  @include fs-18;
}
.almo-fs-16 {
  @include fs-16;
}
.almo-fs-14 {
  @include fs-14;
}
.almo-fs-13 {
  @include fs-13;
}
.almo-fs-small {
  @include fs-small;
}

.lead {
  @include fs-lead;
}
