@import 'variables';
@import 'mixins';

$google: #4285f4;
$facebook: #4267b2;

button,
a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-flat-button,
  &.mat-fab,
  &.mat-mini-fab {
    font-weight: 500;
    letter-spacing: 0.2px;
  }
}

.almo-btn {
  display: inline-flex;
  border-radius: $border-radius;
  padding: 13px 25px;
  font-size: 16px;
  font-weight: 500;
  width: inherit;
  height: auto;
  color: $text-black;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.05);
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
  &__primary {
    background: $purple;
    color: $white;
    &:hover {
      background: $purple-hover;
      color: $white;
    }
    &:active {
      background: $purple-focus;
    }
  }
  &__red {
    background: $red;
    color: $white;
    &:hover {
      background: darken($color: $red, $amount: 10);
    }
    &:active {
      background: darken($color: $red, $amount: 15);
    }
  }
  &__outline-primary {
    color: $purple;
    border-color: $purple;
    background: transparent;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: lighten($purple, 40);
      border-color: $purple-hover;
    }
  }
  &__arrow-r {
    position: relative;
    padding-right: 40px;

    i {
      color: currentColor;
      width: 12.8px;
      height: 16px;
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
      transition: transform 0.25s;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
      }

      &::before {
        height: 2px;
        background-color: currentColor;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg) translateX(0);
        width: 10px;
        height: 10px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
      }
    }
    &:hover {
      i {
        transform: translate(10px, -50%);
      }
    }
  }

  &__arrow-l {
    position: relative;
    padding-left: 40px;

    i {
      color: currentColor;
      width: 12.8px;
      height: 16px;
      display: block;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translate(0, -50%) rotate(180deg);
      transition: transform 0.25s;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
      }

      &::before {
        height: 2px;
        background-color: currentColor;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg) translateX(0);
        width: 10px;
        height: 10px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
      }
    }

    &:hover {
      i {
        transform: translate(-10px, -50%) rotate(180deg);
      }
    }
  }
}

button {
  &.login-with-google,
  &.login-with-facebook {
    padding: 0;
    border-radius: 0;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;

    color: #fff;
    .mat-button-wrapper {
      display: flex;
      padding: 1px;
      .icon {
        width: 50px;
        padding: 5px;
        height: 100%;
        background: #fff;
        align-self: center;
      }
      .text {
        @include fs-14;
        padding: 0 10px;
        align-self: center;
        width: 90%;
      }
    }
  }
  &.login-with-google {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: $google;
    &:hover {
      box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    }

    color: #fff;
  }
  &.login-with-facebook {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background: $facebook;
    &:hover {
      box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    }

    color: #fff;
    .mat-button-wrapper {
      .icon {
        color: $facebook;
      }
    }
  }
}
