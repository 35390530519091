//--Spacer--//
$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: 5px,
    10: 10px,
    15: 15px,
    20: 20px,
    25: 25px,
    30: 30px,
    35: 35px,
    40: 40px,
    45: 45px,
    50: 50px,
    55: 55px,
    60: 60px,
    65: 65px,
    70: 70px,
    75: 75px,
    80: 80px,
    85: 85px,
    90: 90px,
    95: 95px,
    100: 100px,
    115: 115px,
    120: 120px,
    135: 135px,
    140: 140px,
    145: 145px,
    150: 150px,
    155: 155px,
    160: 160px,
    165: 165px,
    170: 170px,
    175: 175px,
    180: 180px,
    200: 200px,
    210: 210px,
    215: 215px,
    225: 225px,
    300: 300px,
  ),
  $spacers
);

//--containers--//
.container-1300 {
  max-width: 1300px;
  margin: 0 auto;
}

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;

$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    37: 37%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    94: 94%,
    95: 95%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

//--classes--//
.font-weight-semi-bold {
  font-weight: 500 !important;
}

@for $i from 1 through 10 {
  .opacity-#{$i} {
    opacity: $i / 10 !important; // 0.1, 0.2 etc
    &.hover-opacity {
      transition: opacity 0.5s ease-in-out;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
