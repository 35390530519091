// Media queries breakpoints
$extraTiny: 320px;
$tiny: 576px;
$small: 768px;
$medium: 992px;
$tablet: 1024px;
$large: 1200px;
$extraLarge: 1600px;

// Additional Variables
$border-radius: 10px;
$box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
$box-shadow-2: 0px 4px 20px rgba(0, 0, 0, 0.3);

// General colors
$white: #ffffff;
$half-white: #f0f2f5;
$black: #000000;
$text-black: #303030;

$material-bg: #fafafa;
$body-bg: $half-white;
$body-bg-light: lighten(
  $color: $body-bg,
  $amount: 2.7,
);
$body-color: $text-black;

// Global color theme
$purple: #1a73e8;
$purple-hover: #125ec1;
$purple-focus: #1354a9;
$orange: #e09600;
$orange-hover: #c78500;
$orange-focus: #ab7405;
$red: #ec422b;
$grey: #c4c4c4;

$dark-grey: #303030;
$mid-grey-1: #535353;
$mid-grey-2: #d6d6d6;
$light-grey-1: #eeeeee;
$light-grey-2: #f7f7f7;

$theme-colors: (
  'primary': $purple,
  'secondary': $orange,
  'red': $red,
  'orange': $orange,
  'grey': $grey,
);

//  http://mcg.mbitson.com/#!?mcgpalette0=%234d4cac&themename=plumppurple

$md-primary: (
  50: #e4eefc,
  100: #bad5f8,
  200: #8db9f4,
  300: #5f9def,
  400: #3c88eb,
  500: #1a73e8,
  600: #176be5,
  700: #1360e2,
  800: #0f56de,
  900: #0843d8,
  A100: #ffffff,
  A200: #cfdaff,
  A400: #9cb3ff,
  A700: #829fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #fbf2e0,
  100: #f6e0b3,
  200: #f0cb80,
  300: #e9b64d,
  400: #e5a626,
  500: #e09600,
  600: #dc8e00,
  700: #d88300,
  800: #d37900,
  900: #cb6800,
  A100: #fff8f3,
  A200: #ffdcc0,
  A400: #ffc08d,
  A700: #ffb274,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-warn: (
  50: #fde8e6,
  100: #f9c6bf,
  200: #f6a195,
  300: #f27b6b,
  400: #ef5e4b,
  500: #ec422b,
  600: #ea3c26,
  700: #e73320,
  800: #e42b1a,
  900: #df1d10,
  A100: #ffffff,
  A200: #ffdbd9,
  A400: #ffaaa6,
  A700: #ff928c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
