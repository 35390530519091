@import 'variables';
@import 'mixins';

.error-text {
  font-size: 16px;
  color: $red;
  padding: 15px 0;
  @include respond-to-max($small) {
    font-size: 14px;
  }
}

label.input-file {
  cursor: pointer;
  input[type='file'] {
    position: absolute;
    left: -10000px;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divider-line {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-black;
  margin: 20px 0;
  span {
    background: transparent;
    margin: 0 10px;
    @include fs-small;
  }
  &:before,
  &:after {
    opacity: 0.5;
    background: $text-black;
    height: 1px;
    content: '';
    flex: 1;
  }
}

hr.separator-line[data-name] {
  position: relative;
  border-top: 1px solid $grey;
  &::before {
    position: absolute;
    content: attr(data-name);
    font-size: 13px;
    top: -11px;
    left: 44%;
    background: $body-bg-light;
    padding: 0 5px;
    color: $grey;
    opacity: 1;
  }
}

button:focus {
  outline: none !important;
}

.text-red {
  color: $red;
}

mat-card.mat-card {
  border-radius: $border-radius;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

// Re-style material forms after login pages
.layout-template {
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix {
    padding: 0.65em 0px;
  }
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix
    > span.mat-form-field-label-wrapper {
    top: -1.3em;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.3em) scale(0.75);
    width: 133.33333%;
  }
}

// For google maps auto complete
.pac-container {
  margin-left: -10px;
  margin-top: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  z-index: 99999 !important;
  position: fixed !important;
}

.pac-logo::after {
  display: none;
}

.pac-item {
  padding: 3px 5px;
}

.email-password {
  max-width: 500px;
  flex-direction: column;
  mat-form-field {
    width: 100%;
  }
}
.social-login-buttons {
  max-width: 500px;
  @include respond-to-max($small) {
    flex-direction: column;
    button:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.privacy-statement {
  a {
    color: $purple;
  }
}

.panel-full-screen {
  max-width: unset !important;
  width: 100% !important;
  height: 100% !important;
  cursor: auto;
  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

.mobile-full-screen {
  @include respond-to-max($tiny) {
    max-width: unset !important;
    width: 100%;
    height: 100%;
    cursor: auto;
    .mat-dialog-container {
      max-width: 100vw;
      max-height: 100vh;
      height: 100%;
      width: 100%;
      .mat-dialog-content {
        max-height: unset !important;
      }
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

// Login popup fix margin for floating label

.input-login-email {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      margin-left: -20px;
    }
  }
}

.country-code-prefix {
  &.mat-form-field-hide-placeholder {
    label {
      padding-left: 55px;
    }
  }
  .mat-form-field-outline-start {
    width: 5px !important;
  }
  .mat-form-field-infix {
    display: inherit;
    mat-select {
      order: 1;
      width: 50px;
      margin-right: 5px;
      .mat-select-value,
      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
    }
    input {
      order: 2;
      width: calc(100% - 60px);
    }
  }
}

.phone-number-prefix {
  &.mat-form-field-hide-placeholder {
    .mat-form-field-prefix {
      display: none;
    }
    .mat-form-field-infix {
      .mat-form-field-label-wrapper {
        margin-left: 0;
      }
    }
  }
  .mat-form-field-prefix {
    top: 0 !important;
    display: inline-block;
    margin-right: 0.2em;
  }
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      margin-left: -30px;
    }
  }
}
